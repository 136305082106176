<template>
    <Dialog header="Choose Portfolio Type" v-model:visible="display" :modal="true" class='choose-portfolio-type-modal'
        :draggable='false' @show='onShown'>
        <Button class='choose-portfolio-type-button' label="Create Aggregate Portfolio"
            @click='onCreateAggregatePortfolio' />
        <p class="type-desc">Aggregate Portfolios are portfolios comprised of group member investment accounts.</p>
        <Button class='choose-portfolio-type-button' label="Create Collaborative Portfolio"
            @click='onCreateCollaborativePortfolio' />
        <p class="type-desc">Collaborative Portfolios are comprised of investment accounts that belong to the group. Trades are made by members submitting “Motions to Invest” which are voted on by the membership.</p>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

import EventBus from '../../../event-bus';
import { mapState } from 'vuex';

export default {
    name: 'ChoosePortfolioTypeModal',
    data() {
        return {
            display: false,
        }
    },
    components: {
        Dialog,
    },

    computed: {
        ...mapState(['selectedGroup']),
    },

    methods: {
        onShown() {
            const dialogElement = document.getElementsByClassName('choose-portfolio-type-modal')[0];
            dialogElement.parentElement.style.position = 'absolute';
        },

        open() {
            this.display = true;
        },

        onCreateAggregatePortfolio() {
            this.display = false;
            EventBus.emit("open-create-edit-group-portfolio-modal");
        },

        onCreateCollaborativePortfolio() {
            this.display = false;
            EventBus.emit("open-create-edit-group-portfolio-modal", { isCollaborativePortfolio: true });
        },
    },
}
</script>

<style>
.choose-portfolio-type-modal {
    position: static;
    font-family: "Trebuchet MS", "Verdana";
    width: 460px;
    border-radius: 16px;
}

.choose-portfolio-type-modal .p-dialog-header {
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.choose-portfolio-type-modal .p-dialog-header .p-dialog-title {
    font-size: 24px;
    font-weight: bold;
}

.choose-portfolio-type-modal .p-dialog-content {
    height: 100%;
    overflow-y: hidden;
    border-top: 1px solid #BFBFBF;
    border-bottom: 1px solid #BFBFBF;
    padding: 24px 16px;
    text-align: center;
    padding: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
</style>
<style scoped>
.type-desc{
    text-align: left;
}

.choose-portfolio-type-button {
    padding: 8px 32px;
    background: #33CC99;
    border: 1px solid #33CC99;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    margin: 20px 0 0;
}

.choose-portfolio-type-button:hover {
    background: #00C083;
    border-color: #00C083;
}

.choose-portfolio-type-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}
</style>